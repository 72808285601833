import { lazy } from 'react';

const Error404 = lazy(() => import('../modules/errors/components/Error404'));
const Error500 = lazy(() => import('../modules/errors/components/Error500'));
const Error401 = lazy(() => import('../modules/errors/components/Error401'));
const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
const Login = lazy(() => import('../modules/auth/views/Login'));
const Projects = lazy(() => import("../modules/profile/components/Projects"));
const Overview = lazy(() => import("../modules/profile/components/Overview"));
const Logout = lazy(() => import('../modules/auth/views/Logout'));
const ForgotPassword = lazy(() => import('../modules/auth/views/ForgotPassword'))
const Registration = lazy(() => import('../modules/auth/views/Registration'))
const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
const ListModules = lazy(() => import('../pages/moduleCreator/views/ListModules'))
const Modules = lazy(() => import('../pages/Modules/views/Modules'))
const Users = lazy(() => import('../pages/users/views/Users'));
const Roles = lazy(() => import('../pages/roles/views/Roles'));
const LoginWIthPhone = lazy(() => import('../modules/auth/views/LoginWIthPhone'));
const LoadingPage = lazy(() => import('../pages/loadingPage/LoadingPage'));
const Profile = lazy(() => import('../pages/profile/views/Profile'));
const AddAdminDashboard = lazy(() => import('../pages/adminDashboard/views/AddAdminDashboard'))
const AdminDashboard = lazy(() => import('../pages/adminDashboard/views/AdminDashboard'))
const AddHubsDistrubutors = lazy(() => import('../pages/hubsDistrubutors/views/AddHubsDistrubutors'))
const HubsDistrubutors = lazy(() => import('../pages/hubsDistrubutors/views/HubsDistrubutors'))
const AddCustomers = lazy(() => import('../pages/Customers/views/AddCustomers'))
const Customers = lazy(() => import('../pages/Customers/views/Customers'))
const ViewCustomers = lazy(() => import('../pages/Customers/views/ViewCustomers'))
const AddRegions = lazy(() => import('../pages/Regions/views/AddRegions'))
const AddLocations = lazy(() => import('../pages/Locations/views/AddLocations'))
const Locations = lazy(() => import('../pages/Locations/views/Locations'))
const AddRoutes = lazy(() => import('../pages/Routes/views/AddRoutes'))
const Routes = lazy(() => import('../pages/Routes/views/Routes'))
const AddBrands = lazy(() => import('../pages/Brands/views/AddBrands'))
const Brands = lazy(() => import('../pages/Brands/views/Brands'))
const AddProductCategories = lazy(() => import('../pages/ProductCategories/views/AddProductCategories'))
const ProductCategories = lazy(() => import('../pages/ProductCategories/views/ProductCategories'))
const AddProducts = lazy(() => import('../pages/Products/views/AddProducts'))
const Products = lazy(() => import('../pages/Products/views/Products'))
const AddBanners = lazy(() => import('../pages/Banners/views/AddBanners'))
const Banners = lazy(() => import('../pages/Banners/views/Banners'))
const ProductPackageSorting = lazy(() => import('../pages/ProductPackageSorting/views/ProductPackageSorting'))
const AddPromoCodes = lazy(() => import('../pages/PromoCodes/views/AddPromoCodes'))
const PromoCodes = lazy(() => import('../pages/PromoCodes/views/PromoCodes'))
const AddOffers = lazy(() => import('../pages/Offers/views/AddOffers'))
const Offers = lazy(() => import('../pages/Offers/views/Offers'))
const Reports = lazy(() => import('../pages/Reports/views/Reports'))
const AddCommunications = lazy(() => import('../pages/Communications/views/AddCommunications'))
const Communications = lazy(() => import('../pages/Communications/views/Communications'))
const AddTicket = lazy(() => import('../pages/Ticket/views/AddTicket'))
const Ticket = lazy(() => import('../pages/Ticket/views/Ticket'))
const AddAttributeHeads = lazy(() => import('../pages/AttributeHeads/views/AddAttributeHeads'))
const AttributeHeads = lazy(() => import('../pages/AttributeHeads/views/AttributeHeads'))
const AddAttributes = lazy(() => import('../pages/Attributes/views/AddAttributes'))
const Attributes = lazy(() => import('../pages/Attributes/views/Attributes'))
const TrackDelivery = lazy(() => import('../pages/TrackDelivery/views/TrackDelivery'))
const Settings = lazy(() => import('../pages/Settings/views/Settings'))
const Uploader = lazy(() => import('../pages/Uploader/views/Uploader'))
const BillingReview = lazy(() => import('../pages/BillingReview/views/BillingReview'))
const AddCashCollectionReport = lazy(() => import('../pages/CashCollectionReport/views/AddCashCollectionReport'))
const Help = lazy(() => import('../pages/help/views/Help'))
const Cities = lazy(() => import('../pages/City/views/Cities'))
const Area = lazy(() => import('../pages/area/views/Area'))
const SubArea = lazy(() => import('../pages/subArea/views/SubArea'))
const DeliveryUsers = lazy(() => import('../pages/deliveryUser/views/DeliveryUsers'));
const AddPackage = lazy(() => import('../pages/Packages/views/AddPackage'))
const Packages = lazy(() => import('../pages/Packages/views/Packages'))
const ComboPackages = lazy(() => import('../pages/ComboPackages/views/ComboPackages'))
// reports
const OrderSortingReport = lazy(() => import('../pages/Reports/views/orderSortingReport/OrderSortingReport'))
const CashCollectionReport = lazy(() => import('../pages/Reports/views/cashCollectionReport/CashCollectionReport'))
const OrderReport = lazy(() => import('../pages/Reports/views/orderReport/OrderReport'))
const CumulativeSalesReport = lazy(() => import('../pages/Reports/views/cummulativeSalesReport/CummulativeSalesReport'))
const ActivityLogsReport = lazy(() => import('../pages/Reports/views/activityLogsReport/ActivityLogsReport'))
const BackOrdersReport = lazy(() => import('../pages/Reports/views/backOrdersReport/BackOrdersReport'))
const CancelOrderReport = lazy(() => import('../pages/Reports/views/cancelOrderReport/CancelOrderReport'))
const CustomerLifeCycleReport = lazy(() => import('../pages/Reports/views/customerLifeCycleReport/CustomerLifeCycleReport'))
const CustomerStatementReport = lazy(() => import('../pages/Reports/views/customerStatementReport/CustomerStatementReport'))
const CustomerSalesReport = lazy(() => import('../pages/Reports/views/customerSalesReport/CustomerSalesReport'))
const CustomerVacationsReport = lazy(() => import('../pages/Reports/views/customerVacationsReport/CustomerVacationsReport'))
const CustomerWalletReport = lazy(() => import('../pages/Reports/views/customerWalletReport/CustomerWalletReport'))
const DeliveryExecutiveReferralReport = lazy(() => import('../pages/Reports/views/deliveryExecutiveReferralReport/DeliveryExecutiveReferralReport'))
const FutureOrdersReport = lazy(() => import('../pages/Reports/views/futureOrdersReport/FutureOrdersReport'))
const HubDeliveryReport = lazy(() => import('../pages/Reports/views/hubDeliveryReport/HubDeliveryReport'))
const LowCreditsReport = lazy(() => import('../pages/Reports/views/lowCreditsReport/LowCreditsReport'))
const NewLocationRequestReport = lazy(() => import('../pages/Reports/views/newLocationRequestReport/NewLocationRequestReport'))
const OrderSheetReport = lazy(() => import('../pages/Reports/views/orderSheetReport/OrderSheetReport'))
const PredictiveAnalysisReport = lazy(() => import('../pages/Reports/views/predictiveAnalysisReport/PredictiveAnalysisReport'))
const ReturnsReport = lazy(() => import('../pages/Reports/views/returnsReport/ReturnsReport'))
const ReverseLogisticsReport = lazy(() => import('../pages/Reports/views/reverseLogisticsReport/ReverseLogisticsReport'))
const RLSummaryReport = lazy(() => import('../pages/Reports/views/rLSummaryReport/RLSummaryReport'))
const SubscriptionReport = lazy(() => import('../pages/Reports/views/subscriptionReport/SubscriptionReport'))
const TransactionsReport = lazy(() => import('../pages/Reports/views/transactionsReport/TransactionsReport'))
const VacationsReport = lazy(() => import('../pages/Reports/views/vacationsReport/VacationsReport'))
const SampleReport = lazy(() => import('../pages/Reports/views/sampleReport/SampleReport'))
const TentativeDemandSheetReport = lazy(() => import('../pages/Reports/views/tentativeDemandSheet/TentativeDemandSheetReport'))
const OtpRequest = lazy(() => import('../pages/OtpRequest/views/OtpRequest'))
const AllTransactionsReport = lazy(() => import('../pages/Reports/views/allTransactionsReport/AllTransactionsReport'))
const GenerateInvoiceReport = lazy(() => import('../pages/Reports/views/generateInvoice/GenerateInvoiceReport'))
const PendingOrderDeliveryBoyReport = lazy(() => import('../pages/Reports/views/pendingOrderDeliveryBoyReport/PendingOrderDeliveryBoyReport'))
const ProductSalesCustomerWise = lazy(() => import('../pages/Reports/views/productSalesCustomerWise/ProductSalesCustomerWise'))
const DeliveryBoyRoutes = lazy(() => import('../pages/Reports/views/deliveryBoyRoutes/DeliveryBoyRoutes'))
const FarmVisitRequestReport = lazy(() => import('../pages/Reports/views/farmVisitRequestReport/FarmVisitRequestReport'))
const DeliveryHeatMapReport = lazy(() => import('../pages/Reports/views/deliveryHeatMapReport/DeliveryHeatMapReport'))
const CustomerCallLogsReport = lazy(() => import('../pages/Reports/views/customerCallLogsReport/CustomerCallLogsReport'))
const CustomerAddressChangeReport = lazy(() => import('../pages/Reports/views/customerAddressChangeReport/CustomerAddressChangeReport'))
const CustomerReferralReport = lazy(() => import('../pages/Reports/views/customerReferralReport/CustomerReferralReport'))
const PartnerRemunerationReport = lazy(() => import('../pages/Reports/views/partnerRemunerationReport/PartnerRemunerationReport'))
//settings
const WalletReason = lazy(() => import('../pages/Settings/views/walletReason/WalletReason'))


const DistributorDashboard = lazy(() => import('../pages/distributorDashboard/views/DistributorDashboard'))
const AddDistributorProducts = lazy(() => import('../pages/distributorProducts/views/AddDistributorProducts'))
const DistributorProducts = lazy(() => import('../pages/distributorProducts/views/DistributorProducts'))
const AddDistributorPackages = lazy(() => import('../pages/distributorPackages/views/AddDistributorPackages'))
const DistributorPackages = lazy(() => import('../pages/distributorPackages/views/DistributorPackages'))
const DistributorReports = lazy(() => import('../pages/distributorReports/views/DistributorReports'))
//imports

export const routeComponentMap: { [x: string]: unknown } = {
	Login,
	Registration,
	ForgotPassword,
	DashboardWrapper,
	Projects,
	Overview,
	Error401,
	Error404,
	Error500,
	ProfilePage,
	BuilderPageWrapper,
	Logout,
	ListModules,
	Modules,
	Users,
	Roles,
	LoginWIthPhone,
	LoadingPage,
	Profile,
	AddAdminDashboard,
	AdminDashboard,
	AddHubsDistrubutors,
	HubsDistrubutors,
	AddCustomers,
	Customers,
	AddRegions,
	// Regions,
	AddLocations,
	Locations,
	AddRoutes,
	Routes,
	AddBrands,
	Brands,
	AddProductCategories,
	ProductCategories,
	AddProducts,
	Products,
	AddBanners,
	Banners,
	ProductPackageSorting,
	AddPromoCodes,
	PromoCodes,
	AddOffers,
	Offers,
	Reports,
	AddCommunications,
	Communications,
	AddTicket,
	Ticket,
	AddAttributeHeads,
	AttributeHeads,
	AddAttributes,
	Attributes,
	TrackDelivery,
	Settings,
	Uploader,
	BillingReview,
	AddCashCollectionReport,
	Help,
	Cities,
	Area,
	SubArea,
	DeliveryUsers,
	ViewCustomers,
	AddPackage,
	Packages,
	// Reports starts
	ActivityLogsReport,
	BackOrdersReport,
	CancelOrderReport,
	CashCollectionReport,
	CumulativeSalesReport,
	CustomerLifeCycleReport,
	CustomerStatementReport,
	CustomerVacationsReport,
	CustomerWalletReport,
	CustomerSalesReport,
	DeliveryExecutiveReferralReport,
	FutureOrdersReport,
	HubDeliveryReport,
	LowCreditsReport,
	NewLocationRequestReport,
	OrderReport,
	OrderSheetReport,
	OrderSortingReport,
	PredictiveAnalysisReport,
	ReturnsReport,
	ReverseLogisticsReport,
	RLSummaryReport,
	SubscriptionReport,
	TransactionsReport,
	VacationsReport,
	SampleReport,
	OtpRequest,
	TentativeDemandSheetReport,
	ComboPackages,
	AllTransactionsReport,
	GenerateInvoiceReport,
	PendingOrderDeliveryBoyReport,
	ProductSalesCustomerWise,
	DeliveryBoyRoutes,
	FarmVisitRequestReport,
	DeliveryHeatMapReport,
	CustomerCallLogsReport,
	CustomerAddressChangeReport,
	CustomerReferralReport,
	PartnerRemunerationReport,
	// Reports ends

	//settings starts
	WalletReason,
	//settings ends
	DistributorDashboard,
	AddDistributorProducts,
	DistributorProducts,
	AddDistributorPackages,
	DistributorPackages,
	DistributorReports,
	//exports

}